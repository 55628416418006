import React, { useEffect } from 'react';
import SEO from '../components/SEO/SEO';

const FaqPage = () => {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.location.pathname = 'FAQs.pdf';
		}
	}, []);

	return (
		<SEO
			title='FAQ'
			description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
		/>
	);
};

export default FaqPage;
